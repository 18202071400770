import React, { useEffect, useState } from 'react';
import Base from '../../utils/base';
import { useLocation } from 'react-router-dom'

export default function Navbar(){
	var base = new Base()
	let {pathname, search} = useLocation()

	const [header_nav_arr] = useState([
		// {title : 'Dashboard', icon : '', url : '/', dropdown_arr : []},
		
		// {title : 'Tracker', icon : '', url : '/talent', dropdown_arr : []},
		{title : '', icon : 'bi bi-bell-fill', url : '/notification', dropdown_arr : []},
	])

	useEffect(()=>{
		
	})

	function useQuery() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	let query = useQuery()

	const [is_sidebar, set_is_sidebar] = useState(false)

	async function sidebar(is_open){
		// console.log(is_open)
		set_is_sidebar(is_open)
	}

	async function logout(){
		var firebaseToken = await localStorage.getItem('firebaseToken')

        var response = await base.request('/auth/logout', 'post', {token : firebaseToken})
        if(response != null){
            if(response.status == 'success'){
                localStorage.clear()
                window.location.href = '/auth/login'
            }
        }
	}

	return(
		<>
			<nav className="navbar navbar-expand-lg navbar-dark p-0 m-0 mt-3 pb-3">
				<div className="container px-md-3">
					<a className="navbar-brand m-0" href="/"><img src={base.img_logo_text} className={'header_logo'} alt="ChristianLifeAcademy-Logo" style={{ width: '15rem' }} /></a>
					<div className="">
						<div className="" id="navbarResponsive" aria-labelledby="navbarResponsive">
							<ul className="navbar-nav text-uppercase ml-auto pt-3 pt-lg-0 px-3" style={{flexDirection : 'unset'}}>
								{
									header_nav_arr.map((data, index) => (
										<li className={"nav-item mr-3" + (pathname === data.url ? ' nav_active' : '')} key={index}>
											{
												data.dropdown_arr.length > 0 ?
												<>
												<a className="nav-link dropdown-toggle text-primary" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">{data.title}</a>
												<div className="dropdown-menu" aria-labelledby="navbarDropdown">
													{
														data.dropdown_arr.map((dataDropdown, indexDropdown)=>(
															<a className={"dropdown-item" + (pathname === dataDropdown.nav ? ' bg-secondary text-white' : '')} href={dataDropdown.nav} key={indexDropdown}><i className={dataDropdown.icon + ' mr-3' + (pathname === dataDropdown.nav ? ' text-white' : '')} style={{color : '#767676'}}></i>{dataDropdown.title}</a>
														))
													}
												</div>
												</>
												:
												<a className="nav-link js-scroll-trigger text-primary" href={data.url} style={{ fontSize: '.9rem' }}>
													{
														data.icon != '' &&
														<h5><i className={data.icon}></i></h5>
													}
													{data.title}
												</a>
											}
										</li>
									))
								}
								<li className="nav-item">
									<a className="nav-link js-scroll-trigger text-primary" href='#!' onClick={()=>logout()}>
										<div className='row m-0'>
											<div className='col-auto p-0'>
												<div className='d-flex align-items-center justify-content-center'>
													<h5 className='m-0'><i className="bi bi-power"></i></h5>
												</div>
											</div>
										</div>
									</a>
								</li>
							</ul>
						</div>                        
					</div>
				</div>
			</nav>
		</>
	)
}